import { getRequest } from '@/utils/request'

export const indexApi = {
  // 首页轮播图
  carouselList: () => {
    return getRequest('/system/api/website/carousel/list')
  },
  // 专区课程
  zoneList: (params = {}) => {
    return getRequest('/resource/api/zone/list', params)
  },
  // 首页导航栏
  websiteNav: () => {
    return getRequest('/system/api/website/nav/list')
  },
  // 站点信息
  websiteInfo: () => {
    return getRequest('/system/api/common/website')
  },
  // 友情链接
  websiteLink: () => {
    return getRequest('/system/api/website/link/list')
  },
  // 分类列表
  categoryList: (params) => {
    return getRequest('/resource/api/category/list', params)
  },
  // 获取枚举
  getEnum: (data) => {
    return getRequest('/system/api/common/enum', data)
  }
}
